:root {
  --cs-green: #29A068;
  --cs-yellow: #F7DF6E;
}
* {
  font-family: "Titillium Web";
}

html, body, .App, #root {
  width: 100%;
  height: fit-content;
  min-height: 100%;
}

.App {
  position: absolute;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content fill;
}

.container {
  min-height: 100%;
  height: fit-content;
}

body {
    background-image: url("https://nettkjeks.ams3.cdn.digitaloceanspaces.com/manet_bg.jpg");
}


header {
  height: 110px;
  background-color: var(--cs-green);
  display: flex;
  align-items: center;
}

header .logo {
  margin-left: 32px;
}

header .logo {
  height: 80px;
}

.card {
  width: 100%;
  max-width: 332px;
  background-color: white;
  padding: 32px;
  height: fit-content;
  transition: all 0.5s;
  position: relative;
}

.card h1 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 16px;
}

label, .cs-input, .cs-button {
  display: block;
}

label {
  font-size: 13px;
  color: #4e4e4e;
}

.cs-input {
  margin: 0px;
  margin-bottom: 5px;
  margin-top: -4px;
  border: none;
  padding: 0px;
  color: black;
  font-size: 18px;
  width: 100%;
  background-color: transparent;
}
.cs-input.disabled {
  border: 0px;
  color: #4e4e4e;
  background-color: rgba(239, 239, 239, 0.3);
}

.cs-input::placeholder {
  font-style: italic;
}

.cs-button {
  padding: 6px 8px;
  text-transform: uppercase;
  background-color: var(--cs-yellow);
  border: none;
  margin-top: 16px;
  transition: background-color .16s ease-in-out;
}

.cs-button.small {
  font-size: 10px;
  line-height: 14px;
}

.cs-button.wide {
  width: 100%;
  margin-top: 32px;
}

.cs-button:hover {
  cursor: pointer;
  background-color: #FFD924;
}

.cs-plus {
  font-size: 16px;
  line-height: 10px;
  display: inline-block;
}

.form-group {
  position: relative;
}

.container {
  display: flex;
  justify-content: center;
}

.is-red {
  color: red;
}

.close-icon {
  position: absolute;
  right: 32px;
  z-index: 99;
  bottom: 6px;
  mask: url(./close-icon.svg);
  background-color: #aaa;
  width: 16px;
  height: 16px;
  transition: background-color .16s ease-in-out;
}

.form-wrapper {
  position: relative;
}

.close-icon:hover {
  background-color: red;
  cursor: pointer;
}

.loading {
  height: 50px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.cs-error {
  color: red;
  margin-top: 8px;
  margin-bottom: -16px;
}

.fade-in {
  animation: fadein .64s;
}

.cs-checkbox {
  width: 16px;
  height: 16px;
  margin: 0px;
  margin-top: 16px;
  display: inline;
  margin-right: 8px;
}

.cs-checkbox-label {
  display: inline;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
